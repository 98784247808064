<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="700px" min-height="500px" class="white">
      <v-card style="padding: 0px" class="white">
        <v-container style="display:flex">
          <v-row>

          <v-col class="col-12 col-md-12 col-lg-6">
            <v-card-title>{{ chosenItem.title }}</v-card-title>
            <v-card-subtitle style="text-align: left">{{
              String(chosenItem.shortDescription).replace(this.regex, "")
            }}</v-card-subtitle>
          </v-col>

          <v-col class="col-12 col-md-12 col-lg-6 align-center justify-center" style="display:flex">
          <span><p>
              <v-icon color="green" style="padding-right: 0px; background-color:none !important">
                mdi-clock-outline
              </v-icon>
              <b style="padding: 10px; font-size:14px">{{ chosenItem.time }}</b>
            </p></span>
          </v-col>

          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-img :src="chosenItem.picture" class="grey lighten-2" style="height: 20%; max-height: 250px;" /><br />
        <v-card-text style="max-height: 80%; text-align: left">
          <b>Recept:</b><br /><br />
          <p v-html="chosenItem.longDescription" class="recipeContainer"></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="background-color: #fcdde8">
      <div>
        <v-row style="display: inline">
          <div class="centerContent">
            <intro-text
              post-title="Vegan Roulette Cookbook"
              v-bind:showText="true"
            />
          </div>
        </v-row>
      </div>
    </div>
    <v-container>
      <v-row style="display: inline">
        <div id="centerContent">
          <br />
          <h1>This is our Vegan cookbook.</h1>
          <br />
          <p>
            The place where all our recipes live. We think nice pictures speak
            louder than words.<br />Click on a picture to learn more on how to
            create these wonderful meals
            <v-icon color="red darken-2"> mdi-heart </v-icon>
          </p>

<v-btn
                class="ma-4 gray black--text customFont white"
                style="text-transform: none"
                to="/"
              >
                <v-icon left dark> mdi-home </v-icon>
                Go back to home</v-btn
              >

        </div>
      </v-row>

      <v-row>
        <v-col
          v-for="recipe in cookbookItems"
          :key="recipe.title"
          class="d-flex child-flex col-12 col-md-6 col-lg-6"
        >
          <v-hover v-slot="{ hover }">
            <v-card class="mx-auto" color="grey lighten-4" max-width="600">
              <v-img
                :src="recipe.picture"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="openDialog(recipe)"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal display-3 white--text extraInfoMouse fill-height"
                    style="background-color:#DB608B; height: 100%; width:100% text-align: center;"
                  ><span style="width:100%" class="tileContainer">
                    <span class="titleTileRecipeSmallScreen">{{ recipe.title }}<br/></span>
                    <span class="descriptionTileRecipe">
                      <span class="titleTileRecipe">{{ recipe.title }}</span><br/>
                      {{ String(recipe.shortDescription).replace(regex, "") }}
                    </span>
                    </span><br/><br/>

                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import IntroText from '../components/IntroText.vue'

export default {
	name: 'Home',
	components: {
		IntroText
	},

	data: function () {
		return {
			dialog: false,
			chosenItem: [],
			routeRecipeID: '',
			regex: /<\/?[^>]+(>|$)/g
		}
	},

	computed: {
		cookbookItems () {
			return this.$store.getters.cookbookItems
		}
	},

	methods: {
		openDialog (item) {
			this.chosenItem = item
			this.dialog = true
		}
	},

	watch: {
		routeRecipeID: function (val) {
			if (val !== '' && this.routeRecipeID !== undefined) {
				this.$store.dispatch('cookbookItems').then(() => {
					const targetRecipe = this.cookbookItems.filter(
						(value, index) =>
							String(value.title).toUpperCase() ===
            String(this.routeRecipeID).toUpperCase()
					)

					if (targetRecipe.length > 0 && targetRecipe !== undefined) {
						this.openDialog(targetRecipe[0])
					}
				})
			}
		}
	},

	mounted () {
		this.routeRecipeID = this.$route.params.idRecipe
	}
}
</script>
<style scoped>
.alternativeFont{
  font-family: 'Hachi Maru Pop', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
.extraInfoMouse{
  font-family: 'Hachi Maru Pop', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: calc(0.5em + 1vw) !important;
  animation: opacity 2s;
}

.extraInfoMouse span{
      padding:calc(0.2em + 1vw) !important;
}

.extraInfoMouse:hover{
  opacity: 0.8;
  cursor: pointer;
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .titleTileRecipe{
    color:bisque !important;
  }

  .tileContainer{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .recipeContainer, .recipeContainer>div, .recipeContainer>div>p{
    color:#666666 !important;
  }

@media only screen and (max-width: 992px) {
  .descriptionTileRecipe{
    display: none;
  }
  .tileContainer{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
  .titleTileRecipeSmallScreen{
    font-size: 3rem;
  }
}
@media only screen and (min-width: 992px) {
  .titleTileRecipeSmallScreen{
    display: none;
  }
  .tileContainer{
    display: block;
    line-height: 2.2rem !important;
  }
  .descriptionTileRecipe{
    display: block;
    top: 20px;
    position: relative;
  }
  .titleTileRecipe{
    display: block;
  }
}
</style>
